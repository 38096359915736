import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import CTABlock from "../../components/blocks/cta_block"

const PositionDetailPage = ({ data }) => {
  const page = data.wagtail.page
  let _ancestor = { children: [], button: {} }
  let _parent = { title: "Positions", slug: "officials" }

  data.wagtail.page.ancestors.forEach((item) => {
    if ("children" in item) {
      _ancestor = item
    } else if ("title" in item && "slug" in item) {
      _parent = item
    }
  })

  return (
    <Layout
      isContent={true}
      subMenu={_ancestor.children}
      button={_ancestor.button}
      parent={"officials"}
      siteTitle={_parent.title}
    >
      <section className="breadcrumb-area breadcrumb-sub-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb nav-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/officials">Officials</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`/officials/${_parent.slug}`}>
                      {_parent.title}
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="sport-safety-area">
        <div className="tab-content-area">
          <div className="container simple-page">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <h1>{page.title}</h1>
                    <p>{page.descriptionSimple}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <br />
                    <div
                      className="c-title-content mb-80"
                      dangerouslySetInnerHTML={{
                        __html: data.wagtail.page.bodySafe,
                      }}
                    />
                  </div>
                </div>
                {data.wagtail.page.relatedResourcesSafe && (
                  <div className="row">
                    <div className="col-12">
                      <h2>Related Resources</h2>
                      <div
                        className="position c-title-content mb-80"
                        dangerouslySetInnerHTML={{
                          __html: data.wagtail.page.relatedResourcesSafe,
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {data.wagtail.page.fullContent &&
        data.wagtail.page.fullContent.length > 0 && (
          <CTABlock data={data.wagtail.page.fullContent[0]}></CTABlock>
        )}
    </Layout>
  )
}

PositionDetailPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query ($id: Int) {
    wagtail {
      page(id: $id) {
        ... on PositionPage {
          id
          bodySafe
          relatedResources
          relatedResourcesSafe
          descriptionSimple
          detail
          button {
            name
            text
            url
          }
          ancestors {
            ... on OfficialsPage {
              children {
                title
                slug
                ... on SimplePage {
                  live
                  isProtected
                }
                ... on PositionsPage {
                  live
                  isProtected
                }
                ... on OfficialPage {
                  live
                  isProtected
                }
                ... on BulletinsPage {
                  live
                  isProtected
                }
              }
              button {
                text
                name
                url
              }
            }
            ... on PositionsPage {
              title
              slug
              live
            }
          }
          title
          fullContent {
            ... on CTABlock {
              id
              title
              image {
                ... on ImageObjectType {
                  src
                  file
                  width
                  height
                }
              }
              description
              orientation
              url
              urlName
            }
          }
        }
      }
    }
  }
`
export default PositionDetailPage
